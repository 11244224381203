
import Vue from "vue";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IGbsHelpActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "gbsHelpActivitiesView",
  mixins: [ActivitiesMixin],

  data(): IGbsHelpActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_GBS_HELP_SUCCESS,
          ADD_GBS_HELP_FAILURE,
          UPDATE_GBS_HELP_SUCCESS,
          UPDATE_GBS_HELP_FAILURE,
          ADD_BULK_GBS_HELP_SUCCESS,
          ADD_BULK_GBS_HELP_FAILURE`.replace(/\s/g, ""),
        activityType: "GbsHelpActivity"
      };
    }
  },
  methods: {
    showImportedPPCs(data: string[]): void {
      this.$router.push({
        path: "/admin/activities/ppc/viewImportData",
        query: {
          ids: data
        }
      });
    }
  }
});
